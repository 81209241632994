@media (max-width: 767px) {
  .form {
    padding: 25px;
  }
  .hideMobile{
    display: none;
  }
}

@media (min-width: 768px) {
  .form {
    padding: 28px;
  }
}

@media (min-width: 992px) {
  .form {
    padding: 20px 50px;
  }
}

.bg-theme {
  background-color: #bd2640 !important;
  color: #fff !important;
}

.color-white {
  color: #fff !important;
}

.MuiButton-containedPrimary {
  background-color: #bd2640 !important;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef !important;

}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  font-size: 15px !important;
}